<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card style="min-width: 35vw;">
      <q-card-section class="text-center text-h6">
        {{ options.description }}
      </q-card-section>

      <q-card-section v-if="options.options" class="text-center">
        <div
          v-for="option in options.options"
          :key="option.id"
          class="q-mb-sm"
        >
          <q-btn
            :label="option.label"
            :color="option.color || 'dark'"
            no-caps
            class="fit"
            @click="handleOk(option.id)"
          />
        </div>
      </q-card-section>

      <q-card-actions v-else align="center">
        <q-btn
          v-if="options.hasClose"
          :color="options.closeColor || 'negative'"
          :label="$t(options.close)"
          no-caps
          @click="handleClose"
        />

        <q-btn
          :color="options.cancelColor || 'negative'"
          :label="$t(options.cancel)"
          no-caps
          @click="handleCancel"
        />

        <q-btn
          :color="options.okColor || 'positive'"
          :label="$t(options.ok)"
          no-caps
          @click="handleOk(true)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data () {
    return {
      isOpen: false,
      options: {
        description: '',
        ok: 'Ok',
        cancel: 'Cancel'
      }
    }
  },
  methods: {
    resolve () {},
    reject () {},
    show (options = {}) {
      this.options = {
        description: '',
        ...options,
        ok: options.ok || 'Ok',
        cancel: options.cancel || 'Cancel'
      }
      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    hide () {
      this.isOpen = false
    },
    handleClose () {
      this.hide()
      this.resolve(null)
    },
    handleCancel () {
      this.hide()
      this.resolve(false)
    },
    handleOk (option) {
      this.hide()
      this.resolve(option || true)
    }
  }
}
</script>
